/* latin-ext */
@font-face {
  font-family: '__Monoton_3b3de9';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/de680e5a4923c83f-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Monoton_3b3de9';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e61861fecd41586c-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Monoton_Fallback_3b3de9';src: local("Arial");ascent-override: 82.86%;descent-override: 28.79%;line-gap-override: 0.00%;size-adjust: 139.43%
}.__className_3b3de9 {font-family: '__Monoton_3b3de9', '__Monoton_Fallback_3b3de9';font-weight: 400;font-style: normal
}.__variable_3b3de9 {--font-monoton: '__Monoton_3b3de9', '__Monoton_Fallback_3b3de9'
}

@font-face {
font-family: '__overcameBold_fcfa67';
src: url(/_next/static/media/dc6ac64952bca708-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__overcameBold_Fallback_fcfa67';src: local("Arial");ascent-override: 59.48%;descent-override: 39.07%;line-gap-override: 0.00%;size-adjust: 117.72%
}.__className_fcfa67 {font-family: '__overcameBold_fcfa67', '__overcameBold_Fallback_fcfa67'
}.__variable_fcfa67 {--font-overcame-bold: '__overcameBold_fcfa67', '__overcameBold_Fallback_fcfa67'
}

@font-face {
font-family: '__overcameOutline_06d129';
src: url(/_next/static/media/85a67cb4de2678ba-s.p.woff2) format('woff2');
font-display: swap;
}@font-face {font-family: '__overcameOutline_Fallback_06d129';src: local("Arial");ascent-override: 60.16%;descent-override: 39.52%;line-gap-override: 0.00%;size-adjust: 116.38%
}.__className_06d129 {font-family: '__overcameOutline_06d129', '__overcameOutline_Fallback_06d129'
}.__variable_06d129 {--font-overcame-outline: '__overcameOutline_06d129', '__overcameOutline_Fallback_06d129'
}

